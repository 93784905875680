import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GridView from "components/GridView";
import Button from "@mui/material/Button";

import { getDatagridContent } from "utils/dataHelper";
import { deleteComplainedPost, fetchFeedbacks } from "repos/feedback";
import Loading from "views/_shared/Loading";
import "./feedback.css";
import { showMessage } from "utils/appHelper";

const FeedbackList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    fetchFeedbacks("message").then((data) => {
      setLoading(false);
      const deleteCallback = async (params) => {
        await deleteComplainedPost(params.row.originalPostId, "message");
        showMessage("Silme işlemi başarıyla gerçekleştirildi.");
        fetchData();
      };
      const { rows, columns } = getDatagridContent(
        data.filter((d) => d.deleteDate === null),
        ["key"],
        null,
        null,
        deleteCallback
      );
      setRows(rows);
      setColumns(columns);
    });
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Loading />;

  return (
    <div id="feedback-list">
      <br />
      <h4>Mesajlara yapılan şikayetlerin listesidir</h4>
      <br />
      Toplam Kayıt: {rows.length}
      <br />
      <br />
      {rows.length ? (
        <GridView rows={rows} columns={columns} />
      ) : (
        "Kayıt bulunamadı"
      )}
    </div>
  );
};

export default FeedbackList;
