import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GridView from "components/GridView";

import { getDatagridContent } from "utils/dataHelper";
import { fetchWhitecodes, pushWhitecode } from "repos/whitecode";
import { delay } from "utils/genericHelper";
import Loading from "views/_shared/Loading";

const WhitecodeList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isPushing, setIsPushing] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    const detailCallback = (params) => {
      navigate("/whitecodes/" + params.id, { state: params.row });
    };

    setLoading(true);
    fetchWhitecodes().then((data) => {
      setLoading(false);
      data?.forEach((d) => {
        const { date, time, place, suspects, story } = JSON.parse(d.post);
        d.date = date;
        d.time = time;
        d.place = place;
        d.suspects = suspects;
        d.story = story;
      });

      const { rows, columns } = getDatagridContent(
        data,
        [
          "id",
          "key",
          "post",
          "isPushed",
          "memberId",
          "time",
          "suspects",
          "story",
        ],
        null,
        detailCallback
      );

      columns.push({
        field: "send",
        headerName: "approved?",
        width: 90,
        renderCell: (params) => {
          const r = params.row;
          return r.isPushed ? (
            <div className="state-button-on">✓</div>
          ) : (
            <div className="state-button-off">BEKLIYOR</div>
          );
          {
            /* <button
              className="state-button-off"
              disabled={isPushing}
              onClick={async () => {
                if (confirm("Emin misiniz?")) {
                  await pushWC(r.id);
                }
              }}
            >
              ONAYLA
            </button> */
          }
        },
      });

      setRows(rows);
      setColumns(columns);
    });
  }, []);

  const pushWC = async (id) => {
    setIsPushing(true);
    await pushWhitecode(id);
    await fetchData();
    setIsPushing(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Loading />;

  return (
    <div id="whitecode-list">
      <br />
      <h4>
        Beyaz kod bildirimlerini görüntüleyip onaylayabilirsiniz. Onaylanan
        beyaz kodlar kullanıcılara bildirim olarak iletilir.
      </h4>
      <br />
      Toplam Kayıt: {rows.length}
      <br />
      <br />
      {rows.length ? (
        <GridView rows={rows} columns={columns} />
      ) : (
        "Kayıt bulunamadı"
      )}
    </div>
  );
};

export default WhitecodeList;
