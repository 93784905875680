import {
  buildResponse,
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  uploadRequest,
} from "./shared";

const loginServer = async ({ username, password }) => {
  try {
    const apiResponse = await postRequest("/login", {
      username,
      password,
    });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const checkJWTServer = async (jwt) => {
  try {
    const apiResponse = await postRequest("/jwt-validate", {
      jwt,
    });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const uploadServer = async (path, ext, file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("path", path);
  formData.append("ext", ext);
  formData.append("origin", "htmlform");

  try {
    const apiResponse = await uploadRequest("/upload", formData);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const sendPushServer = async (title, body) => {
  try {
    const apiResponse = await postRequest("/manual-push", { title, body });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchConfigServer = async (version) => {
  try {
    const apiResponse = await getRequest("/" + version);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchContentsServer = async (category) => {
  try {
    const apiResponse = await postRequest("/contents/filter", {
      category,
    });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchOneContentServer = async (id) => {
  try {
    const apiResponse = await postRequest("/contents/:id", {
      id,
    });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const addContentServer = async (content) => {
  try {
    const apiResponse = await postRequest("/contents", content);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const updateContentServer = async (content) => {
  try {
    const apiResponse = await patchRequest("/contents", content);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const deleteContentServer = async (content) => {
  try {
    const apiResponse = await deleteRequest("/contents", {
      id: content.id,
    });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchContentDocumentsServer = async () => {
  try {
    const apiResponse = await postRequest("/content-documents/filter");
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const addContentDocumentServer = async (contentDocument) => {
  try {
    const apiResponse = await postRequest(
      "/content-documents",
      contentDocument
    );
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const updateContentDocumentServer = async (contentDocument) => {
  try {
    const apiResponse = await patchRequest(
      "/content-documents",
      contentDocument
    );
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const deleteContentDocumentServer = async (id) => {
  try {
    const apiResponse = await deleteRequest("/content-documents", {
      id,
    });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchGroupsServer = async () => {
  try {
    const apiResponse = await postRequest("/groups/filter");
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const updateGroupServer = async (group) => {
  try {
    const apiResponse = await patchRequest("/groups", group);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const approveGroupServer = async (id) => {
  try {
    const apiResponse = await patchRequest("/groups/approval", { id });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchPollsServer = async () => {
  try {
    const apiResponse = await postRequest("/polls/filter");
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const addPollServer = async (poll) => {
  try {
    const apiResponse = await postRequest("/polls", poll);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const updatePollServer = async (poll) => {
  try {
    const apiResponse = await patchRequest("/polls", poll);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const deletePollServer = async (id) => {
  try {
    const apiResponse = await deleteRequest("/polls", {
      id,
    });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchCampaignsServer = async () => {
  try {
    const apiResponse = await postRequest("/campaigns/filter");
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const addCampaignServer = async (campaign) => {
  try {
    const apiResponse = await postRequest("/campaigns", campaign);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const updateCampaignServer = async (campaign) => {
  try {
    const apiResponse = await patchRequest("/campaigns", campaign);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const deleteCampaignServer = async (id) => {
  try {
    const apiResponse = await deleteRequest("/campaigns", {
      id,
    });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchMembersServer = async () => {
  try {
    const apiResponse = await postRequest("/members/filter");
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const getOneMemberServer = async (id) => {
  try {
    const apiResponse = await postRequest(`/members/:id`, { id });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const updateMemberServer = async (member) => {
  try {
    const apiResponse = await patchRequest("/members", member);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchWhitecodesServer = async (filter) => {
  try {
    const apiResponse = await postRequest("/whitecodes/filter", filter);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const pushWhitecodeServer = async (id) => {
  try {
    const apiResponse = await postRequest("/whitecodes/push", { id });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const deleteWhitecodeServer = async (id) => {
  try {
    const apiResponse = await deleteRequest("/whitecodes", { id });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchTargetListsServer = async () => {
  try {
    const apiResponse = await postRequest("/target-lists/filter");
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const createTargetListServer = async (target) => {
  try {
    const apiResponse = await postRequest("/target-lists", target);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const updateTargetListServer = async (target) => {
  try {
    const apiResponse = await patchRequest("/target-lists", target);
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const deleteTargetListServer = async (id) => {
  try {
    const apiResponse = await deleteRequest("/target-lists", { id });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const fetchFeedbacksServer = async (feedbackType) => {
  try {
    const apiResponse = await postRequest("/feedbacks/filter", {
      feedbackType,
    });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const deleteComplainedPostServer = async (originalPostId, feedbackType) => {
  try {
    const apiResponse = await deleteRequest("/feedbacks/post", {
      originalPostId,
      feedbackType,
    });
    return buildResponse(null, apiResponse);
  } catch (err) {
    return buildResponse(err);
  }
};

const methods = {
  uploadServer,
  fetchConfigServer,
  loginServer,
  checkJWTServer,
  sendPushServer,

  fetchContentsServer,
  fetchOneContentServer,
  addContentServer,
  updateContentServer,
  deleteContentServer,

  fetchContentDocumentsServer,
  addContentDocumentServer,
  updateContentDocumentServer,
  deleteContentDocumentServer,

  fetchGroupsServer,
  updateGroupServer,
  approveGroupServer,

  fetchPollsServer,
  addPollServer,
  updatePollServer,
  deletePollServer,

  fetchMembersServer,
  getOneMemberServer,
  updateMemberServer,

  fetchWhitecodesServer,
  pushWhitecodeServer,
  deleteWhitecodeServer,

  fetchTargetListsServer,
  createTargetListServer,
  updateTargetListServer,
  deleteTargetListServer,

  fetchCampaignsServer,
  addCampaignServer,
  updateCampaignServer,
  deleteCampaignServer,

  fetchFeedbacksServer,
  deleteComplainedPostServer,
};

export default methods;
