/* eslint-disable no-bitwise */

export const delay = (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration));

export const generateGuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const format = (val) =>
  val.toString().length > 1 ? val.toString() : "0" + val.toString();

export const isJSON = (str) => {
  if (!str) {
    return false;
  }
  str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, "@");
  str = str.replace(
    /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
    "]"
  );
  str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, "");
  return /^[\],:{}\s]*$/.test(str);
};

export const writeLocalStorage = async (key, data) => {
  //AsyncStorage.setItem(key, JSON.stringify(data));
};

export const readLocalStorage = async (key) => {
  const data = await readLocalStorageRaw(key);
  if (data && data.length) {
    return JSON.parse(data);
  }
  return null;
};

export const readLocalStorageRaw = async (key) => {
  /* const data = await AsyncStorage.getItem(key);
  return data; */
};

export const clearLocalStorage = async () => {
  //await AsyncStorage.clear();
};

export const getRandomInteger = (min, max) => {
  return Math.floor(Math.random() * (max + 1 - min) + min);
};

export const shuffleArray = (source) => {
  const array = [...source];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const handleError = (err) => {
  console.log(err);
};

export const flattenArray = (src) => {
  let result = {};
  if (Array.isArray(src)) {
    src?.forEach((s) => {
      result = { ...result, ...flattenArray(s) };
    });
  } else if (typeof src === "object") {
    result = { ...result, ...src };
  } else {
    return src;
  }

  return result;
};

export const findFileExtension = (uri = "") => {
  return uri.includes(".") ? uri.split(".").at(-1) : null;
};

export const sortAsc = (a, b, field) => {
  if (a[field] < b[field]) {
    return -1;
  } else if (a[field] > b[field]) {
    return 1;
  }
  return 0;
};

export const sortDesc = (a, b, field) => {
  if (a[field] < b[field]) {
    return 1;
  } else if (a[field] > b[field]) {
    return -1;
  }
  return 0;
};
