import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Root from "views/Root";

const Wrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/groups");
    }
    if (location.pathname === "/mobile") {
      navigate("/mobile/groups");
    }
  }, [location]);

  return (
    <div className="app">
      <Root>
        <Outlet />
      </Root>
    </div>
  );
};

export default Wrapper;
