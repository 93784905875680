import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as groupRepo from "repos/group";
import { openImageInNewTab, showMessage } from "utils/appHelper";

const GroupSave = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState({
    title: "",
    summary: "",
    isActive: false,
  });

  useEffect(() => {
    const item = location?.state || {
      id: "",
      title: "",
      summary: "",
      isActive: false,
    };

    setForm({
      id: item.id || "",
      title: item.title || "",
      summary: item.summary || "",
      isActive: item.isActive ?? false,
    });
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    if (!form.title || !form.summary) {
      alert("zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      title: form.title,
      summary: form.summary,
      isActive: form.isActive,
    };

    const saved = await groupRepo.updateGroup(item);
    if (saved) {
      showMessage("Kaydedildi");
      navigate("/groups");
    }
  };

  const handleSwitch = (e) => {
    setForm({ ...form, isActive: e.target.checked });
  };

  return (
    <div id="group-save">
      <br />

      {location?.state?.avatar && (
        <a
          onClick={() =>
            openImageInNewTab(
              `data:image/jpeg;base64,${location?.state?.avatar}`
            )
          }
        >
          <img
            src={`data:image/jpeg;base64,${location?.state?.avatar}`}
            width={100}
            height={100}
          />
        </a>
      )}
      <br />
      <legend>
        <b>Title (İsim/Başlık):</b>
        <textarea
          id="title"
          name="title"
          value={form.title}
          maxLength={50}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Kalan: {50 - form.title?.length}</label>
      </legend>

      <legend>
        <b>Summary (Açıklama):</b>
        <textarea
          id="summary"
          name="summary"
          value={form.summary}
          maxLength={250}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Kalan: {250 - form.summary?.length}</label>
      </legend>

      <legend>
        <b>Aktif:</b> <Switch checked={form.isActive} onChange={handleSwitch} />
      </legend>

      <legend>
        <div>
          <b>Oluşturan</b>: {location?.state?.creatorName}
        </div>
        <div>
          <b>Oluşturma Tarihi</b>: {location?.state?.createDate}
        </div>
        <div>
          <b>Silinme Tarihi</b>: {location?.state?.deleteDate}
        </div>
      </legend>

      <legend>
        <Button variant="contained" onClick={handleSave} className="large">
          KAYDET
        </Button>
      </legend>
    </div>
  );
};

export default GroupSave;
