import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import Login from "views/login";
import NavBar from "views/NavBar";
import MobileNavBar from "views/MobileNavBar";
import "./root.css";
import { useLocation } from "react-router-dom";

const Root = (props) => {
  const location = useLocation();
  const jwt = useSelector((state) => state.app.jwt);
  const isMobile = window.location.href.toLowerCase()?.includes("/mobile");

  return jwt ? (
    <div className={isMobile ? "root-wrapper-mobile" : "root-wrapper"}>
      {isMobile ? <MobileNavBar /> : <NavBar />}
      {isMobile && (
        <div>
          <br />
          <br />
        </div>
      )}
      <h3
        style={{
          position: isMobile ? "relative" : "absolute",
          right: isMobile ? 0 : 50,
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;
        {location.pathname?.split("/")?.at(-1)?.toUpperCase()}
      </h3>

      {props.children}
      <br />
      <br />
      <ToastContainer />
    </div>
  ) : (
    <Login />
  );
};

export default Root;
