import { useEffect, useRef, useState } from "react";
import Switch from "@mui/material/Switch";
import { findFileExtension } from "utils/genericHelper";
import { buildImageUrl } from "utils/appHelper";
import { upload } from "repos/app";

const ContentMedia = ({ images, uploadCallback, deleteImageCallback }) => {
  const fileRef = useRef();
  const [loading, setLoading] = useState(false);

  const handleImagePick = async (e) => {
    if (!e.target.files.length) return false;
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = async () => {
        const { height, width } = image;

        const ext = findFileExtension(file.name);
        if (
          file.name.endsWith(".gif") ||
          file.name.endsWith(".png") ||
          file.name.endsWith(".jpg") ||
          file.name.endsWith(".jpeg")
        ) {
          if (file.size > 5 * 1024 * 1024) {
            alert("file size must be < 5 mb");
            fileRef.current.value = "";
            return;
          } else {
            setLoading(true);
            const url = await upload("content/images", ext, file);
            setLoading(false);
            uploadCallback(url, width, height);
          }
        } else {
          alert("gif / png / jpg / jpeg extensions supported");
          fileRef.current.value = "";
        }
        return true;
      };
    };
  };

  const handleDeleteImage = (e) => {
    deleteImageCallback(e.target.dataset.uri);
  };

  return (
    <legend className="imagebox">
      <h4>Görseller</h4>
      <input
        type="file"
        id="file"
        name="file"
        onChange={handleImagePick}
        accept="image/*"
        ref={fileRef}
        style={{ visibility: loading ? "hidden" : "visible" }}
      />
      {loading && "uploading..."}
      <div className="gallery">
        {images.map((image, i) => {
          return (
            <div key={i}>
              <img
                src={buildImageUrl(image.uri)}
                className="gallery-item"
                alt="i"
              />
              <br />
              <button
                data-uri={image.uri}
                onClick={handleDeleteImage}
                color="error"
              >
                SİL
              </button>
            </div>
          );
        })}
      </div>
    </legend>
  );
};

export default ContentMedia;
