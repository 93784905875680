import { memo, useEffect, useState } from "react";
import { fetchTargetLists } from "repos/target";
import "./shared.css";

const TargetListSelection = ({ initialValue, changeCallback }) => {
  const [allLists, setAllLists] = useState([]);
  const [lists, setLists] = useState([]);
  const [selectedListId, setSelectedListId] = useState("-1");

  useEffect(() => {
    fetchTargetLists().then((data) => {
      setAllLists(data);
    });
  }, []);

  useEffect(() => {
    const newLists = [];
    initialValue?.forEach((id) => {
      const item = allLists.find(
        (el) => el.id.toLowerCase() === id.toLowerCase()
      );
      if (item) newLists.push(item);
    });
    setLists(newLists);
  }, [initialValue, allLists.length]);

  const handleAddList = (e) => {
    if (selectedListId !== "-1") {
      if (!lists.find((el) => el.id === selectedListId)) {
        const item = allLists.find((el) => el.id === selectedListId);
        const newLists = [...lists, item];
        setLists(newLists);
        changeCallback(newLists);
      }
    }
  };

  const handleDeleteList = (e) => {
    const id = e.target.dataset.id;
    const newLists = lists.filter((el) => el.id !== id);
    setLists(newLists);
    changeCallback(newLists);
  };

  const handleListChange = (e) => {
    setSelectedListId(e.target.value);
  };

  return (
    <table id="target-list-selection">
      <tbody>
        <tr>
          <td>
            <select value={selectedListId} onChange={handleListChange}>
              <option value={"-1"}>Seçiniz</option>
              {allLists.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.title}
                </option>
              ))}
            </select>
          </td>
          <td>
            <button onClick={handleAddList} data-action="add">
              EKLE
            </button>
          </td>
        </tr>

        {lists.map((el) => {
          return (
            <tr key={el.id}>
              <td>{el.title}</td>
              <td>
                <button
                  data-id={el.id}
                  data-action="delete"
                  onClick={handleDeleteList}
                >
                  x ÇIKAR
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default memo(TargetListSelection);
