import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GridView from "components/GridView";
import Button from "@mui/material/Button";

import { getDatagridContent } from "utils/dataHelper";
import { CONTENT_TYPE } from "utils/enums";
import { fetchContentDocuments } from "repos/contentDocument";
import Loading from "views/_shared/Loading";

const DocumentList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    fetchContentDocuments().then((data) => {
      setLoading(false);
      const folderList = data?.filter((d) => !!d.folder)?.map((f) => f.folder);
      folderList.sort();
      setFolders(Array.from(new Set(folderList)));
      const editCallback = (params) => {
        navigate("/documents/" + params.id, {
          state: { ...params.row, folderList },
        });
      };
      const { rows, columns } = getDatagridContent(data, ["key"], editCallback);
      setRows(rows);
      setColumns(columns);
    });
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = async (event) => {
    navigate("/documents/0", { state: { folderList: folders } });
  };

  if (loading) return <Loading />;

  return (
    <div id="document-list">
      <Button variant="contained" onClick={handleAdd}>
        +EKLE (Dokuman)
      </Button>
      <br />
      <br />
      Toplam Kayıt: {rows.length}
      <br /> <br />
      {rows.length ? (
        <GridView rows={rows} columns={columns} />
      ) : (
        "Kayıt bulunamadı"
      )}
    </div>
  );
};

export default DocumentList;
