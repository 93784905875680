import RichTextEditor from "react-simple-wysiwyg";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import { CONTENT_TYPE } from "utils/enums";
import {
  addContent,
  deleteContent,
  updateContent,
  fetchOneContent,
} from "repos/content";
import ContentMedia from "./ContentMedia";
import TagList from "../_shared/TagList";
import { showMessage } from "utils/appHelper";
import "./content.css";

const ContentSave = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState({
    id: "",
    priority: "",
    title: "",
    category: "",
    origin: "",
    summary: "",
    description: "",
    url: "",
    videoUrl: "",
    images: [],
    tags: "",
  });

  useEffect(() => {
    if (location?.state?.id && !form.id) {
      fetchOneContent(location?.state?.id).then((data) => {
        const post = JSON.parse(data.post);
        setForm({
          id: data.id,
          priority: data.priority || "-1",
          title: data.title || "",
          category: data.category || "",
          origin: post.origin || "",
          summary: post.summary || "",
          description: post.description || "",
          url: post.url || "",
          videoUrl: post.videoUrl || "",
          images: post.images || [],
          tags: data.tags || "",
        });
      });
    }
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setForm({ ...form, description: e.target.value });
  };

  const handleCategoryChange = async (event) => {
    setForm({
      ...form,
      category: event.target.value,
    });
  };

  const handleTagChange = (newTags) => {
    setForm({
      ...form,
      tags: newTags.map((t) => `${t.name}=${t.value}`).join(","),
    });
  };

  const handleSave = async (e) => {
    if (
      !form.title ||
      !form.category ||
      !form.origin ||
      !form.summary ||
      !form.description ||
      !form.url
    ) {
      alert("zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      priority: form.priority == -1 ? null : form.priority,
      title: form.title,
      category: form.category,
      post: JSON.stringify({
        origin: form.origin,
        summary: form.summary,
        description: form.description,
        url: form.url,
        videoUrl: form.videoUrl,
        images: form.images,
      }),
      tags: form.tags,
    };

    const saved = form.id ? await updateContent(item) : await addContent(item);
    if (saved) {
      showMessage("Kaydedildi");
      navigate("/contents");
    }
  };

  const handleDelete = async (e) => {
    if (window.confirm("Silmek istiyor musunuz?")) {
      await deleteContent(form);
      showMessage("Silindi");
      navigate("/contents");
    }
  };

  const handleUpload = (data, width, height) => {
    setForm({
      ...form,
      images: [
        ...form.images,
        {
          uri: data,
          ratio: +(width / height).toFixed(2),
        },
      ],
    });
  };

  const handleDeleteImage = (uri) => {
    setForm({
      ...form,
      images: form.images.filter((el) => el.uri !== uri),
    });
  };

  const handlePriorityChange = async (event) => {
    setForm({
      ...form,
      priority: event.target.value,
    });
  };

  if (location?.state?.id && !form.id) return null;

  return (
    <div id="content-save">
      <legend>
        <b>* Title: (Başlık)</b>
        <textarea
          id="title"
          name="title"
          value={form.title}
          maxLength={500}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Kalan: {500 - form.title?.length}</label>
      </legend>
      <legend>
        <b>Priority: (Öncelik, önceliği yüksek olan daha önce görünür)</b>
        <Select
          labelId="priority"
          id="priority"
          value={form.priority}
          label="Öncelik(Priority)"
          onChange={handlePriorityChange}
        >
          <MenuItem value={-1}>Yok</MenuItem>
          {new Array(10).fill(0).map((_, i) => (
            <MenuItem key={i} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </legend>
      <legend>
        <b>* Category: (Kategori)</b>
        <Select
          labelId="category"
          id="category"
          value={form.category}
          label="Kategori"
          onChange={handleCategoryChange}
        >
          <MenuItem value={CONTENT_TYPE.News}>News (Haber)</MenuItem>
          <MenuItem value={CONTENT_TYPE.Announcement}>
            Announcement (Duyuru)
          </MenuItem>
          <MenuItem value={CONTENT_TYPE.Congress}>Congress (Kongre)</MenuItem>
          <MenuItem value={CONTENT_TYPE.Education}>Education (Eğitim)</MenuItem>
        </Select>
      </legend>
      <legend>
        <b>* Origin: (Kaynak, ör: saglik.gov.tr)</b>
        <input
          type="text"
          id="origin"
          name="origin"
          value={form.origin}
          maxLength={500}
          onChange={handleTextChange}
        />
      </legend>
      <legend>
        <b>* Summary: (Özet, uygulamada listelemede görünen kısa açıklama)</b>
        <textarea
          id="summary"
          name="summary"
          value={form.summary}
          maxLength={500}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Kalan: {500 - form.summary?.length}</label>
      </legend>
      <legend>
        <b>* Description: (Detay ekranında görünen uzun açıklama)</b>
        <RichTextEditor
          value={form.description}
          onChange={handleDescriptionChange}
          style={{ height: 300 }}
        />
        <label className="counter">
          Kalan: {2500 - form.description?.length}
        </label>
      </legend>
      <legend>
        <b>* URL: (Kaynağın linki, ör: https://saglik.gov.tr)</b>
        <textarea
          id="url"
          name="url"
          value={form.url}
          maxLength={500}
          onChange={handleTextChange}
        ></textarea>
      </legend>
      <legend>
        <ContentMedia
          images={form.images}
          uploadCallback={handleUpload}
          deleteImageCallback={handleDeleteImage}
        />
      </legend>

      <legend>
        <b>Video URL</b>
        <input
          type="text"
          id="videoUrl"
          name="videoUrl"
          value={form.videoUrl}
          maxLength={500}
          onChange={handleTextChange}
        />
      </legend>

      <TagList initialTags={form.tags} changeCallback={handleTagChange} />

      <legend>
        <Button variant="contained" onClick={handleSave}>
          KAYDET
        </Button>
        &nbsp;
        {form.id && (
          <Button variant="outlined" onClick={handleDelete} color="error">
            SİL
          </Button>
        )}
      </legend>
    </div>
  );
};

export default ContentSave;
