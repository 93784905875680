import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchGroups, approveGroup } from "repos/group";
import { formatDDDDHHMM, setGlobalLoading, showMessage } from "utils/appHelper";
import { sortAsc, sortDesc } from "utils/genericHelper";
import Loading from "views/_shared/Loading";

const GroupList = () => {
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({ field: "title", asc: true });
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const response = await fetchGroups();
    setLoading(false);

    setData(response);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleApprove = async (e) => {
    if (confirm("Grubu onaylamak istediginize emin misiniz?")) {
      setLoading(true);
      const id = e.target.dataset.id;
      const response = await approveGroup(id);
      setLoading(false);
      if (response) {
        const index = data.findIndex((d) => d.id === id);
        if (index > -1) {
          const tmp = [...data];
          tmp[index].isActive = true;
          setData(tmp);
        }
        showMessage("Grup onaylandı!");
      } else {
        alert("Grup onaylanmadı!");
      }
    }
  };

  const handleSort = (index) => {
    const fields = ["title", "creatorName", "createDate"];
    const field = fields[index];
    const tmp = [...data];

    if (sort.field === field) {
      tmp.sort((a, b) =>
        sort.asc ? sortAsc(a, b, field) : sortDesc(a, b, field)
      );
      setSort({ field, asc: !sort.asc });
    } else {
      tmp.sort((a, b) => sortAsc(a, b, field));
      setSort({ field, asc: true });
    }

    setData(tmp);
  };

  const data1 = data.filter((d) => !d.isActive);
  data1.sort((a, b) => sortDesc(a, b, "createDate"));

  const data2 = data.filter((d) => d.isActive);

  return (
    <div id="group-list-mobile">
      {loading && <Loading />}

      <button onClick={fetchData}>↻ YENILE</button>
      <br />
      <br />

      <h3>ONAY BEKLEYEN</h3>

      <table>
        <thead>
          <tr>
            <th></th>
            <th>Üye adı</th>
            <th>Grup adı</th>
            <th>Tarih</th>
          </tr>
        </thead>
        <tbody>
          {data1.map((g, i) => {
            return (
              <tr key={i}>
                <td>
                  <button
                    data-id={g.id}
                    onClick={handleApprove}
                    style={{ padding: 10 }}
                  >
                    Onayla
                  </button>
                </td>
                <td>{g.creatorName || "-"}</td>
                <td>{g.title}</td>
                <td>{formatDDDDHHMM(g.createDate)}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2}>{data1.length === 0 ? "No records found" : ""}</td>
          </tr>
        </tfoot>
      </table>
      <h3>ONAYLANMIŞ</h3>
      <table>
        <thead>
          <tr>
            <th>
              <button onClick={() => handleSort(0)}>Grup adı</button>
            </th>
            <th>
              <button onClick={() => handleSort(1)}>Oluşturan</button>
            </th>
            <th>
              <button onClick={() => handleSort(2)}>Tarih</button>
            </th>
          </tr>
        </thead>
        <tbody>
          {data2.map((g, i) => {
            return (
              <tr key={i}>
                <td>{g.title}</td>
                <td>{g.creatorName || "-"}</td>
                <td>{formatDDDDHHMM(g.createDate)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GroupList;
