import { json, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as pollRepo from "repos/poll";
import TargetListSelection from "views/_shared/TargetListSelection";
import { showMessage } from "utils/appHelper";

const PollSave = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const targetListsRef = useRef([]);

  const [form, setForm] = useState({
    title: "",
    summary: "",
    targetLists: "",
    targetUrl: "",
    showcase: false,
    isActive: false,
  });

  useEffect(() => {
    const item = location?.state || {
      id: "",
      title: "",
      summary: "",
      targetLists: "",
      detail: '{"targetUrl":"","showcase":false}',
      isActive: false,
    };

    setForm({
      id: item.id || "",
      title: item.title || "",
      summary: item.summary || "",
      targetLists: item.targetLists || "",
      targetUrl: JSON.parse(item.detail).targetUrl,
      showcase: JSON.parse(item.detail).showcase,
      isActive: item.isActive ?? false,
    });
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    if (!form.title || !form.summary || !form.targetUrl) {
      alert("Zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      title: form.title,
      summary: form.summary,
      targetLists: targetListsRef.current?.map((el) => el.id).join(","),
      detail: JSON.stringify({
        targetUrl: form.targetUrl,
        showcase: form.showcase,
      }),
      isActive: form.isActive,
    };

    const saved = form.id
      ? await pollRepo.updatePoll(item)
      : await pollRepo.addPoll(item);
    if (saved) {
      showMessage("Kaydedildi");
      navigate("/polls");
    }
  };

  const handleActiveSwitch = (e) => {
    setForm({ ...form, isActive: e.target.checked });
  };

  const handleShowcaseSwitch = (e) => {
    setForm({ ...form, showcase: e.target.checked });
  };

  const handleDelete = async (e) => {
    if (confirm("Silmek istediginize emin misiniz?")) {
      await pollRepo.deletePoll(form.id);
      showMessage("Silindi");
      navigate("/polls");
    }
  };

  const handleTargetListChange = useCallback((val) => {
    targetListsRef.current = val;
  });

  return (
    <div id="poll-save">
      <legend>
        <b>* Title: (Baslık)</b>
        <textarea
          id="title"
          name="title"
          value={form.title}
          maxLength={500}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Kalan: {500 - form.title?.length}</label>
      </legend>

      <legend>
        <b>* Summary: (Açıklama)</b>
        <textarea
          id="summary"
          name="summary"
          value={form.summary}
          maxLength={1000}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Kalan: {1000 - form.summary?.length}</label>
      </legend>

      <legend>
        TARGET LISTS: (Hedef Listeler)
        <TargetListSelection
          initialValue={form.targetLists?.split(",")?.filter((el) => !!el)}
          changeCallback={handleTargetListChange}
        />
      </legend>

      <legend>
        <b>Is showcase: (Anasayfada görünsün mü)</b>
        <Switch checked={form.showcase} onChange={handleShowcaseSwitch} />
      </legend>

      <legend>
        <b>* Target Url: (Hedef linki)</b>
        <textarea
          id="targetUrl"
          name="targetUrl"
          value={form.targetUrl}
          maxLength={1000}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">
          Kalan: {1000 - form.targetUrl?.length}
        </label>
      </legend>

      <legend>
        <b>Aktif:</b>{" "}
        <Switch checked={form.isActive} onChange={handleActiveSwitch} />
      </legend>

      <legend>
        <Button variant="contained" onClick={handleSave} className="large">
          KAYDET
        </Button>
        &nbsp;
        {form.id && (
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            className="large"
          >
            SİL
          </Button>
        )}
      </legend>
    </div>
  );
};

export default PollSave;
