import { json, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import RichTextEditor from "react-simple-wysiwyg";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as campaignRepo from "repos/campaign";
import TagList from "views/_shared/TagList";
import TargetListSelection from "views/_shared/TargetListSelection";

import agricultureIcon from "../../assets/campaign/agriculture.png";
import artIcon from "../../assets/campaign/art.png";
import barberIcon from "../../assets/campaign/barber.png";
import butcherIcon from "../../assets/campaign/butcher.png";
import cafeIcon from "../../assets/campaign/cafe.png";
import carpenterIcon from "../../assets/campaign/carpenter.png";
import carpetIcon from "../../assets/campaign/carpet.png";
import carsIcon from "../../assets/campaign/cars.png";
import cleanIcon from "../../assets/campaign/clean.png";
import cleaningIcon from "../../assets/campaign/cleaning.png";
import computerIcon from "../../assets/campaign/computer.png";
import constructionIcon from "../../assets/campaign/construction.png";
import cosmeticsIcon from "../../assets/campaign/cosmetics.png";
import ecommerceIcon from "../../assets/campaign/ecommerce.png";
import educationIcon from "../../assets/campaign/education.png";
import electricIcon from "../../assets/campaign/electric.png";
import financeIcon from "../../assets/campaign/finance.png";
import fishIcon from "../../assets/campaign/fish.png";
import foodIcon from "../../assets/campaign/food.png";
import furnitureIcon from "../../assets/campaign/furniture.png";
import gasIcon from "../../assets/campaign/gas.png";
import giftIcon from "../../assets/campaign/gift.png";
import herbalIcon from "../../assets/campaign/herbal.png";
import hotelIcon from "../../assets/campaign/hotel.png";
import jewelIcon from "../../assets/campaign/jewel.png";
import kitchenIcon from "../../assets/campaign/kitchen.png";
import medicalIcon from "../../assets/campaign/medical.png";
import musicIcon from "../../assets/campaign/music.png";
import opticIcon from "../../assets/campaign/optic.png";
import otherIcon from "../../assets/campaign/other.png";
import petsIcon from "../../assets/campaign/pets.png";
import phoneIcon from "../../assets/campaign/phone.png";
import pressIcon from "../../assets/campaign/press.png";
import productionIcon from "../../assets/campaign/production.png";
import realestateIcon from "../../assets/campaign/realestate.png";
import safetyIcon from "../../assets/campaign/safety.png";
import sportIcon from "../../assets/campaign/sport.png";
import stationeryIcon from "../../assets/campaign/stationery.png";
import toysIcon from "../../assets/campaign/toys.png";
import transportIcon from "../../assets/campaign/transport.png";
import watchIcon from "../../assets/campaign/watch.png";
import waterIcon from "../../assets/campaign/water.png";
import wearIcon from "../../assets/campaign/wear.png";
import weddingIcon from "../../assets/campaign/wedding.png";
import { MenuItem, Select } from "@mui/material";
import { showMessage } from "utils/appHelper";

const categoryIcons = {
  agriculture: agricultureIcon,
  art: artIcon,
  barber: barberIcon,
  butcher: butcherIcon,
  cafe: cafeIcon,
  carpenter: carpenterIcon,
  carpet: carpetIcon,
  cars: carsIcon,
  clean: cleanIcon,
  cleaning: cleaningIcon,
  computer: computerIcon,
  construction: constructionIcon,
  cosmetics: cosmeticsIcon,
  ecommerce: ecommerceIcon,
  education: educationIcon,
  electric: electricIcon,
  finance: financeIcon,
  fish: fishIcon,
  food: foodIcon,
  furniture: furnitureIcon,
  gas: gasIcon,
  gift: giftIcon,
  herbal: herbalIcon,
  hotel: hotelIcon,
  jewel: jewelIcon,
  kitchen: kitchenIcon,
  medical: medicalIcon,
  music: musicIcon,
  optic: opticIcon,
  other: otherIcon,
  pets: petsIcon,
  phone: phoneIcon,
  press: pressIcon,
  production: productionIcon,
  realestate: realestateIcon,
  safety: safetyIcon,
  sport: sportIcon,
  stationery: stationeryIcon,
  toys: toysIcon,
  transport: transportIcon,
  water: waterIcon,
  watch: watchIcon,
  wear: wearIcon,
  wedding: weddingIcon,
};

const CampaignSave = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const targetListsRef = useRef([]);

  const [displayType, setDisplayType] = useState("0");

  const [form, setForm] = useState({
    priority: "",
    title: "",
    targetLists: "",
    category: "food",
    closable: false,
    showcase: false,
    list: true,
    targetUrl: "",
    sizeValue: "",
    sizeUnit: "",
    imageInfoUrl: "",
    imageInfoThumbnailUrl: "",
    textInfoBrand: "",
    //textInfoBrandLong: "",
    textInfoSubtitle1: "",
    textInfoSubtitle2: "",
    textInfoSubtitle3: "",
    //textInfoSubtitle4: "",
    //textInfoSubtitle5: "",
    isActive: false,
    tags: "",
    htmlInfo: "",
  });

  useEffect(() => {
    const item = location?.state || {
      id: "",
      priority: "",
      title: "",
      targetLists: "",
      detail:
        '{"category":"food","closable":false,"showcase":false,"list":true,"targetUrl":"","size":{"value":0,"unit":"%"},' +
        '"imageInfo":null,' +
        '"textInfo":{"brand":"","subtitle1":"","subtitle2":"","subtitle3":""},' +
        '"htmlInfo":null}',
      isActive: false,
      tags: "",
    };

    const detail = JSON.parse(item.detail);
    setDisplayType(detail.imageInfo ? "1" : "0");

    setForm({
      id: item.id || "",
      priority: item.priority || "-1",
      title: item.title || "",
      targetLists: item.targetLists || "",
      category: detail.category,
      closable: detail.closable,
      showcase: detail.showcase,
      list: detail.list,
      targetUrl: detail.targetUrl,
      sizeValue: detail.size.value,
      sizeUnit: detail.size.unit,
      imageInfoUrl: detail.imageInfo?.imageUrl,
      imageInfoThumbnailUrl: detail.imageInfo?.thumbnailUrl,
      textInfoBrand: detail.textInfo?.brand || "",
      //textInfoBrandLong: detail.textInfo?.brandLong,
      textInfoSubtitle1: detail.textInfo?.subtitle1,
      textInfoSubtitle2: detail.textInfo?.subtitle2,
      textInfoSubtitle3: detail.textInfo?.subtitle3,
      //textInfoSubtitle4: detail.textInfo?.subtitle4,
      //textInfoSubtitle5: detail.textInfo?.subtitle5,
      isActive: item.isActive ?? false,
      tags: item.tags || "",
      htmlInfo: detail?.htmlInfo || "",
    });
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    if (!form.title || !(form.imageInfoUrl || form.textInfoBrand)) {
      alert("Zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      priority: form.priority == -1 ? null : form.priority,
      title: form.title,
      targetLists: targetListsRef.current?.map((el) => el.id).join(","),
      detail: JSON.stringify({
        category: form.category,
        closable: form.closable,
        showcase: form.showcase,
        list: form.list,
        targetUrl: form.targetUrl,
        size: {
          value: form.sizeValue,
          unit: form.sizeUnit,
        },
        imageInfo:
          form.imageInfoUrl || form.imageInfoThumbnailUrl
            ? {
                imageUrl: form.imageInfoUrl,
                thumbnailUrl: form.imageInfoThumbnailUrl,
              }
            : null,
        textInfo: form.textInfoBrand
          ? {
              brand: form.textInfoBrand,
              brandLong: "",
              subtitle1: form.textInfoSubtitle1,
              subtitle2: form.textInfoSubtitle2,
              subtitle3: form.textInfoSubtitle3,
              //subtitle4: "",
              //subtitle5: "",
            }
          : null,
        htmlInfo: form.htmlInfo || null,
      }),
      isActive: form.isActive,
      tags: form.tags,
    };

    const saved = form.id
      ? await campaignRepo.updateCampaign(item)
      : await campaignRepo.addCampaign(item);
    if (saved) {
      showMessage("Kaydedildi");
      navigate("/campaigns");
    }
  };

  const handleActiveSwitch = (e) => {
    setForm({ ...form, isActive: e.target.checked });
  };

  const handleShowcaseSwitch = (e) => {
    setForm({ ...form, showcase: e.target.checked });
  };

  const handleListSwitch = (e) => {
    setForm({ ...form, list: e.target.checked });
  };

  const handleClosableSwitch = (e) => {
    setForm({ ...form, closable: e.target.checked });
  };

  const handleUnitChange = (e) => {
    setForm({ ...form, sizeUnit: e.target.value });
  };

  const handleCategoryChange = (e) => {
    setForm({ ...form, category: e.target.value });
  };

  const handleDelete = async (e) => {
    if (confirm("Silmek istediginize emin misiniz?")) {
      await campaignRepo.deleteCampaign(form.id);
      showMessage("Silindi");
      navigate("/campaigns");
    }
  };

  const handleTagChange = (newTags) => {
    setForm({
      ...form,
      tags: newTags.map((t) => `${t.name}=${t.value}`).join(","),
    });
  };

  const handleTargetListChange = useCallback((val) => {
    targetListsRef.current = val;
  });

  const handlePriorityChange = async (event) => {
    setForm({
      ...form,
      priority: event.target.value,
    });
  };

  const handleDisplayTypeChange = (event) => {
    setDisplayType(event.target.value);
  };

  return (
    <div id="campaign-save">
      <br />
      <br />
      <legend>
        <b>Title: (Kampanya Adı)</b>
        <textarea
          id="title"
          name="title"
          value={form.title}
          maxLength={500}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Kalan: {500 - form.title?.length}</label>
      </legend>

      <legend>
        <b>Brand: (Firma adı)</b>
        <input
          id="textInfoBrand"
          name="textInfoBrand"
          type="text"
          value={form.textInfoBrand}
          onChange={handleTextChange}
        />
      </legend>

      <legend>
        <b>Priority: (Öncelik, önceliği yüksek olan daha önce görünür)</b>
        <Select
          labelId="priority"
          id="priority"
          value={form.priority}
          label="Öncelik(Priority)"
          onChange={handlePriorityChange}
        >
          <MenuItem value={-1}>Yok</MenuItem>
          {new Array(10).fill(0).map((_, i) => (
            <MenuItem key={i} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </legend>

      <legend>
        <b>Category: (Kategorisi)</b>
        <select value={form.category} onChange={handleCategoryChange}>
          <option value="agriculture">Tarım / Bitki</option>
          <option value="art">Sanat / Kültür</option>
          <option value="barber">Kuaför / Berber / Güzellik / Bakım</option>
          <option value="butcher">Kasap / Et ürünleri</option>
          <option value="cafe">Kafe / Çay bahçesi</option>
          <option value="carpenter">Marangoz / Ahşap</option>
          <option value="carpet">Halı / Sergi</option>
          <option value="cars">Araba</option>
          <option value="clean">Temizlik</option>
          <option value="cleaning">Kuru temizleme</option>
          <option value="computer">Bilgisayar</option>
          <option value="construction">İnşaat</option>
          <option value="cosmetics">Kozmetik / Parfüm</option>
          <option value="ecommerce">Eticaret / Pazarlama</option>
          <option value="education">Eğitim/ Okul</option>
          <option value="electric">Elektrik</option>
          <option value="finance">Finans / Para / Döviz</option>
          <option value="fish">Balık / Balıkçı</option>
          <option value="food">Yemek / Gıda / Restoran</option>
          <option value="furniture">Mobilya</option>
          <option value="gas">Petrol</option>
          <option value="gift">Hediye</option>
          <option value="herbal">Bitkisel / Aktar</option>
          <option value="hotel">Hotel</option>
          <option value="jewel">Mücevher / Kuyumcu </option>
          <option value="kitchen">Mutfak / Beyazesya</option>
          <option value="medical">Tıbbi / Sağlık</option>
          <option value="music">Muzik / Çalğı / Eğlence</option>
          <option value="optic">Optik / Göz / Gözlük</option>
          <option value="other">Diger</option>
          <option value="pets">Evcil hayvan</option>
          <option value="phone">Telefon</option>
          <option value="press">Basın / Yayın</option>
          <option value="production">Üretim / İmalat</option>
          <option value="realestate">Emlak</option>
          <option value="safety">Güvenlik</option>
          <option value="sport">Spor / Gym</option>
          <option value="stationery">Kırtasiye</option>
          <option value="toys">Oyuncak</option>
          <option value="transport">Nakliye</option>
          <option value="watch">Saat</option>
          <option value="water">Su</option>
          <option value="wear">Giyim</option>
          <option value="wedding">Düğün</option>
        </select>
        <img
          src={categoryIcons[form.category] || otherIcon}
          width={50}
          height={50}
        />
      </legend>

      <legend>
        <b>Target Url:</b>
        * Hedef url, kampanya url i varsa tıklayınca bu url'e gider.
        <br />
        * self girilirse kampanyalar sayfasını açar
        <br />* boş kalırsa herhangi bir aksiyon olmaz
        <textarea
          id="targetUrl"
          name="targetUrl"
          value={form.targetUrl}
          maxLength={1000}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">
          Kalan: {1000 - form.targetUrl?.length}
        </label>
      </legend>

      <legend>
        <b>Is closable: (Kullanıcı kapatabilir mi)</b>
        <Switch checked={form.closable} onChange={handleClosableSwitch} />
      </legend>

      <legend>
        <b>Is showcase: (Anasayfada görünsün mü)</b>
        <Switch checked={form.showcase} onChange={handleShowcaseSwitch} />
      </legend>

      <legend>
        <b>Is list: (Kampanya listesinde görünsün mü)</b>
        <Switch checked={form.list} onChange={handleListSwitch} />
      </legend>

      <legend>
        <div>
          <input
            type="radio"
            name="image"
            value={"0"}
            checked={displayType == "0"}
            onChange={handleDisplayTypeChange}
          />
          Metin &nbsp;&nbsp;&nbsp;&nbsp;
          <input
            type="radio"
            name="image"
            value={"1"}
            checked={displayType == "1"}
            onChange={handleDisplayTypeChange}
          />
          Banner
        </div>
        {displayType == "1" && (
          <div>
            <br />
            Banner resmin ölçüleri burada verilir. Örneğin birim olarak "yüzde",
            değer olarak "50" girilirse, resmin yüksekliği genişliğinin yarısı
            kadar olur. Eğer birim olarak "sayı" değer olarak "120" girilirse
            genişlikten bağımsız olarak yükseklik 120 pixel olur.
            <br />
            <br />
            <b>Size unit: (Yükseklik birimi)</b>
            <select value={form.sizeUnit} onChange={handleUnitChange}>
              <option value="value">Sayı</option>
              <option value="%">% (Yüzde)</option>
            </select>
            <br />
            <br />
            <b>Size value: (Yükseklik değeri)</b>
            <input
              id="sizeValue"
              name="sizeValue"
              type="number"
              value={form.sizeValue}
              onChange={handleTextChange}
            />
            <br />
            <br />
            <br />
            <b>Resim linki:</b>
            <input
              id="imageInfoThumbnailUrl"
              name="imageInfoThumbnailUrl"
              type="text"
              value={form.imageInfoThumbnailUrl}
              onChange={handleTextChange}
            />
          </div>
        )}
        {displayType == "0" && (
          <div>
            <br />

            <b>Subtitle1: (1.satır)</b>
            <input
              id="textInfoSubtitle1"
              name="textInfoSubtitle1"
              type="text"
              value={form.textInfoSubtitle1}
              onChange={handleTextChange}
            />
            <br />
            <br />
            <b>Subtitle2: (2.satır)</b>
            <input
              id="textInfoSubtitle2"
              name="textInfoSubtitle2"
              type="text"
              value={form.textInfoSubtitle2}
              onChange={handleTextChange}
            />
            <br />
            <br />
            <b>Subtitle3: (3.satır)</b>
            <input
              id="textInfoSubtitle3"
              name="textInfoSubtitle3"
              type="text"
              value={form.textInfoSubtitle3}
              onChange={handleTextChange}
            />
          </div>
        )}
      </legend>

      <legend>
        <b>TARGET LISTS: (Hedef Listeler)</b>
        <h4>
          Hedef listeler uygulamada İl/İlçe/Meslek/Branş filtrelemelerinde
          kullanılır
        </h4>
        <TargetListSelection
          initialValue={form.targetLists?.split(",")?.filter((el) => !!el)}
          changeCallback={handleTargetListChange}
        />
      </legend>

      <TagList initialTags={""} changeCallback={handleTagChange} />

      <legend>
        <b>
          * HTML: (Buraya girilen html diğer imaj ve textleri ezer.) (kampanya
          listesinde geçerlidir, Bannerlarda etkisi yoktur)
        </b>
        <br />
        <RichTextEditor
          value={form.htmlInfo}
          onChange={handleTextChange}
          style={{ height: 100 }}
          id="htmlInfo"
          name="htmlInfo"
        />
      </legend>

      <legend>
        Aktif: <Switch checked={form.isActive} onChange={handleActiveSwitch} />
      </legend>

      <div>
        <Button variant="contained" onClick={handleSave} className="large">
          KAYDET
        </Button>
        &nbsp;&nbsp;&nbsp;
        {form.id && (
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            className="large"
          >
            SİL
          </Button>
        )}
      </div>
    </div>
  );
};

export default CampaignSave;
