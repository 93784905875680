import { useState } from "react";
import "./push.css";
import { Button } from "@mui/material";
import { sendPush } from "repos/app";
import { showMessage } from "utils/appHelper";
import { useNavigate } from "react-router-dom";

const index = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    title: "",
    body: "",
  });

  const handleTextChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSend = async () => {
    if (!form.title || !form.body) alert("Lütfen ilgili alanları doldurunuz.");
    else {
      await sendPush(form.title, form.body);
      showMessage("Mesaj başarıyla gönderildi.");
      navigate("/groups");
    }
  };

  return (
    <div id="push">
      <br />
      <h4>
        Buradan gönderilen mesaj tüm kullanıcılara bildirim olarak gidecektir,
        dikkatli kullanınız!
      </h4>
      <legend>
        <b>* Bildirim Başlığı</b>
        <input
          type="text"
          id="title"
          name="title"
          value={form.title}
          maxLength={50}
          onChange={handleTextChange}
        />
        <label className="counter">Kalan: {50 - form.title?.length}</label>
      </legend>
      <legend>
        <b>* Bildirim Metni</b>
        <textarea
          id="body"
          name="body"
          value={form.body}
          maxLength={250}
          onChange={handleTextChange}
        />
        <label className="counter">Kalan: {250 - form.body?.length}</label>
      </legend>
      <legend>
        <Button variant="contained" onClick={handleSend} className="large">
          GÖNDER
        </Button>
      </legend>
    </div>
  );
};

export default index;
