import { Button } from "@mui/material";
import { memo, useEffect, useState } from "react";
import cities from "data/cities.json";
import jobs from "data/jobs.json";
import branches from "data/branches.json";
import "./shared.css";

const TagList = memo(({ initialTags = "", changeCallback }) => {
  const [tagType, setTagType] = useState("0");
  const [tags, setTags] = useState([]);
  const [form, setForm] = useState({
    city: "-1",
    county: "-1",
    job: "-1",
    branch: "-1",
  });

  useEffect(() => {
    if (initialTags) {
      const tagArray = initialTags.split(",");
      tagArray?.forEach((t) => {
        const tag = t.split("=");
        const newTag = {
          name: tag[0],
          value: tag[1],
          label:
            tag[0] === "city"
              ? cities.find((c) => c.key === tag[1])?.label
              : tag[0] === "job"
                ? jobs.find((j) => j.key === tag[1])?.label
                : tag[0] === "branch"
                  ? Object.values(branches).map(
                      (list) => list.find((b) => b.key === tag[1])?.label
                    )
                  : tag[0] === "county"
                    ? cities.map(
                        (c) => c.counties.find((ct) => ct.key === tag[1])?.label
                      )
                    : "",
        };
        setTags((prev) =>
          prev.find((t) => t.name === newTag.name && t.value === newTag.value)
            ? prev
            : [...prev, newTag]
        );
      });
    }
  }, [initialTags]);

  const handleTagTypeChange = (e) => {
    setTagType(e.target.value);
    setForm({
      city: "-1",
      county: "-1",
      job: "-1",
      branch: "-1",
    });
  };

  const handleAddTag = () => {
    const newTags = structuredClone(tags);

    if (form.city !== "-1") {
      if (!newTags.find((t) => t.name === "city" && t.value === form.city)) {
        newTags.push({
          name: "city",
          value: form.city,
          label: cities.find((c) => c.key === form.city).label,
        });
      }

      if (form.county !== "-1") {
        if (
          !newTags.find((t) => t.name === "county" && t.value === form.county)
        ) {
          newTags.push({
            name: "county",
            value: form.county,
            label: cities
              .find((c) => c.key === form.city)
              .counties.find((ct) => ct.key === form.county).label,
          });
        }
      }
    }

    if (form.job !== "-1") {
      if (!newTags.find((t) => t.name === "job" && t.value === form.job)) {
        newTags.push({
          name: "job",
          value: form.job,
          label: jobs.find((j) => j.key === form.job).label,
        });
      }

      if (form.branch !== "-1") {
        if (
          !newTags.find((t) => t.name === "branch" && t.value === form.branch)
        ) {
          newTags.push({
            name: "branch",
            value: form.branch,
            label: branches["list" + form.job].find(
              (b) => b.key === form.branch
            ).label,
          });
        }
      }
    }

    setTags(newTags);

    setForm({
      city: "-1",
      county: "-1",
      job: "-1",
      branch: "-1",
    });
    changeCallback(newTags);
  };

  const handleDeleteTag = (e) => {
    const index = +e.target.dataset.index;
    const newTags = tags.filter((t, i) => i !== index);
    setTags(newTags);

    changeCallback(newTags);
  };

  const handleSelect = (id, val) => {
    setForm({ ...form, [id]: val });
  };

  const countyData =
    form.city === "-1" ? [] : cities.find((c) => c.key === form.city).counties;

  const branchData = form.job === "-1" ? [] : branches["list" + form.job];

  return (
    <legend id="tag-list">
      <h2>TAGS (ETİKETLER)</h2>
      Burada içeriğe ilişkilendirilen etiketler arama sonuçlarında filtrelemede
      kullanılır.
      <br />
      <br />
      <table>
        <tbody>
          <tr>
            <td>Tag tipi:</td>
            <td>
              <select onChange={handleTagTypeChange} value={tagType}>
                <option value="0">Şehir/İlçe</option>
                <option value="1">Meslek/Branş</option>
              </select>
            </td>
          </tr>
          {tagType === "0" ? (
            <tr>
              <td>
                İl:{" "}
                <CityDropdown
                  id="city"
                  value={form.city}
                  changeCallback={handleSelect}
                />
              </td>
              <td>
                İlçe:{" "}
                <CountyDropdown
                  id="county"
                  value={form.county}
                  countyData={countyData}
                  changeCallback={handleSelect}
                />
              </td>
            </tr>
          ) : (
            <tr>
              <td>
                Meslek:{" "}
                <JobDropdown
                  id="job"
                  value={form.job}
                  changeCallback={handleSelect}
                />
              </td>
              <td>
                Branş:{" "}
                <BranchDropdown
                  id="branch"
                  value={form.branch}
                  branchData={branchData}
                  changeCallback={handleSelect}
                />
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={2}>
              <button onClick={handleAddTag}>TAG EKLE</button>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          {tags.map((t, i) => {
            return (
              <tr key={i}>
                <td>{t.name}</td>
                <td>{t.label}</td>
                <td>
                  <button data-index={i} onClick={handleDeleteTag}>
                    {" "}
                    SİL{" "}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </legend>
  );
});

const CityDropdown = ({ id, value, changeCallback }) => {
  return (
    <select value={value} onChange={(e) => changeCallback(id, e.target.value)}>
      <option value={"-1"}>Hepsi</option>
      {cities.map((c) => {
        return (
          <option key={c.key} value={c.key}>
            {c.label}
          </option>
        );
      })}
    </select>
  );
};

const CountyDropdown = ({ id, value, countyData, changeCallback }) => {
  return (
    <select value={value} onChange={(e) => changeCallback(id, e.target.value)}>
      <option value={"-1"}>Hepsi</option>
      {countyData.map((c) => {
        return (
          <option key={c.key} value={c.key}>
            {c.label}
          </option>
        );
      })}
    </select>
  );
};

const JobDropdown = ({ id, value, changeCallback }) => {
  return (
    <select value={value} onChange={(e) => changeCallback(id, e.target.value)}>
      <option value={"-1"}>Hepsi</option>
      {jobs.map((j) => {
        return (
          <option key={j.key} value={j.key}>
            {j.label}
          </option>
        );
      })}
    </select>
  );
};

const BranchDropdown = ({ id, value, branchData, changeCallback }) => {
  return (
    <select value={value} onChange={(e) => changeCallback(id, e.target.value)}>
      <option value={"-1"}>Hepsi</option>
      {branchData.map((b) => {
        return (
          <option key={b.key} value={b.key}>
            {b.label}
          </option>
        );
      })}
    </select>
  );
};

export default TagList;
