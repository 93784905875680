import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchMembers, updateMember } from "repos/member";
import { formatDDDDHHMM, setGlobalLoading, showMessage } from "utils/appHelper";
import { sortAsc, sortDesc } from "utils/genericHelper";
import Loading from "views/_shared/Loading";

const MemberList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const response = await fetchMembers();
    setLoading(false);

    setData(response);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleStatus = async (e) => {
    const id = e.target.getAttribute("data-id");
    const active = e.target.getAttribute("data-active") === "true";

    let text =
      "Üye durumu #0# tir, #1# olarak degistirmek istediginize misiniz?";
    if (active) {
      text = text.replace("#0#", "AKTIF").replace("#1#", "PASIF");
    } else {
      text = text.replace("#0#", "PASIF").replace("#1#", "AKTIF");
    }

    if (confirm(text)) {
      await updateMember({ id, active: !active });
      await fetchData();
      showMessage("Güncellendi!");
    }
  };

  data.sort((a, b) => sortAsc(a, b, "firstName"));

  return (
    <div id="member-list-mobile">
      {loading && <Loading />}

      <button onClick={fetchData}>↻ YENILE</button>
      <br />
      <br />

      <h3>ÜYELER</h3>

      <table>
        <thead>
          <tr>
            <th>İşlem</th>
            <th>Onay durumu</th>
            <th>Üye adı</th>
            <th>Şehir/İlçe</th>
            <th>Meslek/Branş</th>
          </tr>
        </thead>
        <tbody>
          {data.map((m, i) => {
            return (
              <tr key={i}>
                <td>
                  <button
                    data-id={m.id}
                    data-active={m.active}
                    onClick={toggleStatus}
                    style={{ padding: 4 }}
                  >
                    {m.active ? "Pasifle" : "Aktifle"}
                  </button>
                </td>
                <td>{m.active ? "AKTİF" : "PASİF"}</td>
                <td>{m.firstName + " " + m.lastName}</td>
                <td>
                  {m.city} / {m.county}
                </td>
                <td>
                  {m.job} / {m.branch}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2}>{data.length === 0 ? "Kayıt bulunamadı!" : ""}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default MemberList;
