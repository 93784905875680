import api from "integration/api";
import { handleError } from "utils/genericHelper";

export const fetchFeedbacks = async (feedbackType) => {
  try {
    const response = await api.fetchFeedbacksServer(feedbackType);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const deleteComplainedPost = async (originalPostId, feedbackType) => {
  try {
    const response = await api.deleteComplainedPostServer(
      originalPostId,
      feedbackType
    );
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};
