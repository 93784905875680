import RichTextEditor from "react-simple-wysiwyg";
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { CONTENT_TYPE } from "utils/enums";
import {
  addContentDocument,
  deleteContentDocument,
  updateContentDocument,
} from "repos/contentDocument";
import TagList from "../_shared/TagList";
import { showMessage } from "utils/appHelper";

const DocumentSave = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [folders, setFolders] = useState([]);
  const [form, setForm] = useState({
    id: "",
    documentId: "",
    title: "",
    url: "",
    tags: "",
    folder: "",
    newFolder: "",
  });

  useEffect(() => {
    const { folderList, ...item } = location.state || {};

    setForm({
      id: item.id || "",
      documentId: item.documentId || "",
      title: item.title || "",
      url: item.url || "",
      tags: item.tags || "",
      folder: item.folder || "-1",
      newFolder: "",
    });

    const uniqueFolderList = Array.from(
      new Set(folderList?.filter((d) => !!d))
    );
    uniqueFolderList.sort();

    setFolders(uniqueFolderList);
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTagChange = (newTags) => {
    setForm({
      ...form,
      tags: newTags.map((t) => `${t.name}=${t.value}`).join(","),
    });
  };

  const handleSave = async (e) => {
    let folder = form.folder;

    if (!form.title || !form.url || (folder === "-2" && !form.newFolder)) {
      alert("Zorunlu alanları doldurunuz");
      return;
    }

    //YOK
    if (folder === "-1") {
      folder = null;
    }
    //Yeni klasör
    else if (folder === "-2" && form.newFolder) {
      folder = form.newFolder;
    }
    //listeden seçim
    else {
      folder = form.folder;
    }

    const item = {
      id: form.id,
      documentId: form.documentId,
      title: form.title,
      tags: form.tags,
      folder,
      url: form.url, //document url
    };

    const saved = form.id
      ? await updateContentDocument(item)
      : await addContentDocument(item);

    if (saved) {
      showMessage("Kaydedildi");
      navigate("/documents");
    }
  };

  const handleDelete = async (e) => {
    if (window.confirm("Silmek istiyor musunuz?")) {
      await deleteContentDocument(form.id);
      showMessage("Silindi");
      navigate("/documents");
    }
  };

  const handleFolderChange = async (event) => {
    const f = event.target.value;
    setForm((prev) => ({ ...prev, folder: f }));
  };

  return (
    <div id="document-save">
      <br />
      <br />
      <legend>
        <h3>Klasör</h3>
        <Select
          labelId="folders"
          id="folders"
          value={folders.length > 2 ? form.folder : "-1"}
          label="Klasör"
          onChange={handleFolderChange}
        >
          <MenuItem value="-1">Yok</MenuItem>
          <MenuItem value="-2">Yeni Klasör Gireceğim</MenuItem>
          {folders.map((f) => (
            <MenuItem key={f} value={f}>
              {f}
            </MenuItem>
          ))}
        </Select>
        <br />
        {form.folder === "-2" && (
          <legend>
            Yeni klasör adı giriniz:
            <input
              type="text"
              id="newFolder"
              name="newFolder"
              value={form.newFolder}
              maxLength={100}
              onChange={handleTextChange}
            />
          </legend>
        )}

        <br />
      </legend>

      <legend>
        <h3>Title (Başlık/İsim)</h3>
        <textarea
          id="title"
          name="title"
          value={form.title}
          maxLength={100}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Kalan: {100 - form.title?.length}</label>
      </legend>

      <legend>
        <h3>URL (Dökümanın linki)</h3>
        <textarea
          id="url"
          name="url"
          value={form.url}
          maxLength={250}
          onChange={handleTextChange}
        ></textarea>
        <label className="counter">Kalan: {250 - form.url?.length}</label>
      </legend>

      <TagList initialTags={form.tags} changeCallback={handleTagChange} />

      <legend>
        <Button variant="contained" onClick={handleSave}>
          KAYDET
        </Button>{" "}
        &nbsp;
        {form.id && (
          <Button variant="outlined" onClick={handleDelete} color="error">
            SİL
          </Button>
        )}
      </legend>
    </div>
  );
};

export default DocumentSave;
