import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GridView from "components/GridView";

import { getDatagridContent } from "utils/dataHelper";
import { fetchFeedbacks } from "repos/feedback";
import Loading from "views/_shared/Loading";
import "./feedback.css";

const FeedbackList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    fetchFeedbacks(null).then((data) => {
      setLoading(false);

      const { rows, columns } = getDatagridContent(data, [
        "originalPost",
        "originalPostId",
        "deleteDate",
      ]);
      setRows(rows);
      setColumns(columns);
    });
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Loading />;

  return (
    <div id="feedback-list">
      <br />
      <h4>
        Kullanıcılar tarafından uygulamanın iletişim bölümünden gönderilen mesaj
        listesidir
      </h4>
      <br />
      Toplam Kayıt: {rows.length}
      <br />
      <br />
      {rows.length ? (
        <GridView rows={rows} columns={columns} />
      ) : (
        "Kayıt bulunamadı"
      )}
    </div>
  );
};

export default FeedbackList;
