import api from "integration/api";
import { handleError } from "utils/genericHelper";

export const fetchContents = async (category) => {
  const response = await api.fetchContentsServer(category);

  if (response.success) {
    return response.data || [];
  } else {
    handleError(response.error);
  }
};

export const fetchOneContent = async (id) => {
  const response = await api.fetchOneContentServer(id);
  if (response.success) {
    return response.data;
  } else {
    handleError(response.error);
  }
};

export const addContent = async (content) => {
  const response = await api.addContentServer(content);

  if (response.success) {
    return response.data;
  } else {
    handleError(response.error);
  }
};

export const updateContent = async (content) => {
  const response = await api.updateContentServer(content);

  if (response.success) {
    return response.data;
  } else {
    handleError(response.error);
  }
};

export const deleteContent = async (content) => {
  const response = await api.deleteContentServer(content);

  if (response.success) {
    return response.data;
  } else {
    handleError(response.error);
  }
};
