import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  updateTargetList,
  createTargetList,
  deleteTargetList,
} from "repos/target";
import { showMessage } from "utils/appHelper";
import cities from "data/cities.json";
import jobs from "data/jobs.json";

const TargetSave = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState({
    id: "",
    title: "",
    city: "",
    job: "",
  });

  const findInMatcher = (field, matcher) => {
    if (!matcher.includes(field)) return "";

    const parts = matcher.split(",");
    const el = parts.find((p) => p.includes(field + "="));
    return el ? el.split("=")[1] : "";
  };

  useEffect(() => {
    const item = location?.state || {
      id: "",
      title: "",
      matcher: "",
    };

    setForm({
      id: item.id || "",
      title: item.title || "",
      city: findInMatcher("city", item.matcher),
      job: findInMatcher("job", item.matcher),
    });
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    if (!form.title) {
      alert("zorunlu alanları doldurunuz");
      return;
    }

    const newCity = form.city.replace("-1", "");
    const newJob = form.job.replace("-1", "");

    if (!newCity && !newJob) {
      alert("Şehir yada meslek seçilmedi");
      return;
    }

    const matchers = [];
    if (newCity) matchers.push("city=" + form.city);
    if (newJob) matchers.push("job=" + form.job);
    matchers.sort();

    const item = {
      id: form.id,
      title: form.title,
      matcher: matchers.join(","),
    };

    const saved = form.id
      ? await updateTargetList(item)
      : await createTargetList(item);
    if (saved) {
      showMessage("Kaydedildi");
      navigate("/targets");
    }
  };

  const handleDelete = async (e) => {
    if (!confirm("Silmek istediginize emin misiniz?")) return;

    await deleteTargetList(e.target.dataset.id);
    showMessage("Silindi");
    navigate("/targets");
  };

  const handleSelectChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <div id="target-save">
      <br />
      <h4>
        Hedef listeler kampanya veya anketlerde belirlenmiş hedef kitlesine özgü
        gösterimler için kullanılır.
      </h4>
      <br />
      <legend>ID: {form.id}</legend>
      <legend>
        <b>Title: (liste adı)</b>
        <input
          type="title"
          id="title"
          name="title"
          value={form.title}
          maxLength={250}
          onChange={handleTextChange}
        />
        <label className="counter">Kalan: {250 - form.title?.length}</label>
      </legend>
      <h4>Aşağıdaki filtrelerden 1 yada 2 sini seçiniz</h4>
      <legend>
        <b>ŞEHİR:</b>
        <select
          onChange={handleSelectChange}
          id="city"
          name="city"
          value={form.city}
        >
          <option value="-1">Seçilmedi</option>
          {cities.map((c) => (
            <option key={c.key} value={c.key}>
              {c.label}
            </option>
          ))}
        </select>
        <br />
        &nbsp;
        <br />
        <b>MESLEK:</b>
        <select
          onChange={handleSelectChange}
          id="job"
          name="job"
          value={form.job}
        >
          <option value="-1">Seçilmedi</option>
          {jobs.map((j) => (
            <option key={j.key} value={j.key}>
              {j.label}
            </option>
          ))}
        </select>
        <br />
      </legend>
      <legend>
        <Button variant="contained" onClick={handleSave} className="large">
          KAYDET
        </Button>
        &nbsp;
        {form.id && (
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            className="large"
            data-id={form.id}
          >
            SİL
          </Button>
        )}
      </legend>
    </div>
  );
};

export default TargetSave;
