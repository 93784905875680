import { toast } from "react-toastify";
import moment from "moment";
import { setJWTAction, setLoadingAction } from "_redux/app/actions";
import { store } from "_redux/store";

export const ddddHHmm = "DD MMM dddd, HH:mm";

export const DBMaxInteger = 2147483640; //sql server max integer

export const shortDuration = (date) => {
  const d1 = moment(date);
  const d2 = moment(Date.now());
  const days = d2.diff(d1, "days");

  if (days < 1) return d1.format("HH:mm");

  return days + "g";
};

export const formatDDDDHHMM = (dt) => moment(dt).format(ddddHHmm);

export const setGlobalLoading = (val) => {
  store.dispatch(setLoadingAction(val));
};

export const buildImageUrl = (path) => {
  if (path?.startsWith("http")) return path;

  const base = process.env.REACT_APP_S3;
  return base + path;
};

export const logout = () => {
  localStorage.removeItem("jwt");
  store.dispatch(setJWTAction(null));
};

export const showMessage = (msg) => {
  toast(msg, { position: "top-center", autoClose: 250, type: "success" });
};

export const openImageInNewTab = (url) => {
  var w = window.open("about:blank");
  setTimeout(() => {
    w.document.body.appendChild(w.document.createElement("iframe")).src = url;
    w.document.body.style.margin = 0;
    w.document.getElementsByTagName("iframe")[0].style.width = "100%";
    w.document.getElementsByTagName("iframe")[0].style.height = "100%";
    w.document.getElementsByTagName("iframe")[0].style.border = 0;
  }, 250);
};
