import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { findFileExtension } from "utils/genericHelper";
import { upload } from "repos/app";
import "./index.css";

const Index = () => {
  const [uploadedItemPath, setUploadedItemPath] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();

  const handleUpload = async () => {
    const ext = findFileExtension(file.name);

    if (file.size > 20 * 1024 * 1024) {
      alert("file size must be < 20 mb");
      return;
    } else {
      setLoading(true);
      const url = await upload("uploaded", ext, file);
      setLoading(false);
      setUploadedItemPath(url);
    }
  };

  const handleFilePick = async (e) => {
    if (!e.target.files.length) return false;

    const file = e.target.files[0];
    setFile(file);
    setUploadedItemPath("");
  };

  return (
    <div id="upload">
      <legend>
        <input
          type="file"
          id="file1"
          name="file1"
          onChange={handleFilePick}
          accept="image/*,.pdf,.csv,.doc,.docx,.xls,.xslx,.zip"
        />
        <Button
          variant="contained"
          onClick={handleUpload}
          className="large"
          disabled={loading}
        >
          YÜKLE
        </Button>
      </legend>

      <legend>
        {loading ? (
          "uploading..."
        ) : (
          <div>
            Yüklenen dosyanın linki: <br />
            <br />
            {uploadedItemPath
              ? process.env.REACT_APP_S3 + uploadedItemPath
              : "-"}
          </div>
        )}
      </legend>
    </div>
  );
};

export default Index;
