import { store } from "_redux/store";
import api from "integration/api";
import { handleError } from "utils/genericHelper";
import { setJWTAction } from "_redux/app/actions";

export const login = async ({ username, password }) => {
  try {
    const response = await api.loginServer({
      username,
      password,
    });
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const upload = async (path, ext, file) => {
  try {
    const response = await api.uploadServer(path, ext, file);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};

export const checkJWT = async () => {
  const reduxJwt = store.getState().app.jwt;
  const localJwt = localStorage.getItem("jwt");
  const jwt = localJwt || reduxJwt;
  if (!jwt) {
    return false;
  }

  try {
    const response = await api.checkJWTServer(jwt);
    if (response.success) {
      store.dispatch(setJWTAction(response.data));
      localStorage.setItem("jwt", jwt);
      return jwt;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
  return false;
};

export const sendPush = async (title, body) => {
  try {
    const response = await api.sendPushServer(title, body);
    if (response.success) {
      return response.data;
    } else {
      handleError(response.error);
    }
  } catch (err) {
    handleError(err);
  }
};
