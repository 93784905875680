import api from "integration/api";
import { handleError } from "utils/genericHelper";

export const fetchContentDocuments = async () => {
  const response = await api.fetchContentDocumentsServer();

  if (response.success) {
    return response.data || [];
  } else {
    handleError(response.error);
  }
};

export const addContentDocument = async (contentDocument) => {
  const response = await api.addContentDocumentServer(contentDocument);

  if (response.success) {
    return response.data;
  } else {
    handleError(response.error);
  }
};

export const updateContentDocument = async (contentDocument) => {
  const response = await api.updateContentDocumentServer(contentDocument);

  if (response.success) {
    return response.data;
  } else {
    handleError(response.error);
  }
};

export const deleteContentDocument = async (id) => {
  const response = await api.deleteContentDocumentServer(id);

  if (response.success) {
    return response.data;
  } else {
    handleError(response.error);
  }
};
