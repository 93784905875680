import { Link } from "react-router-dom";
import { logout } from "utils/appHelper";

const MobileNavBar = () => {
  return (
    <div className="navbar-mobile">
      <nav>
        <div>
          <Link to={`groups`}>Gruplar</Link>
        </div>
        <div>
          <Link to={`members`}>Üyeler</Link>
        </div>
        <div>
          <Link to={`whitecodes`}>Beyazkod</Link>
        </div>
        <div>
          <Link to={`/mobile`} onClick={logout}>
            ÇIKIŞ
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default MobileNavBar;
