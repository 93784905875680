import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GridView from "components/GridView";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import { getDatagridContent } from "utils/dataHelper";
import { CONTENT_TYPE } from "utils/enums";
import { fetchContents } from "repos/content";
import Loading from "views/_shared/Loading";

const ContentList = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState(CONTENT_TYPE.News);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async (_category) => {
      setLoading(true);
      fetchContents(_category).then((data) => {
        setLoading(false);
        const editCallback = (params) => {
          navigate("/contents/" + params.id, { state: params.row });
        };
        const { rows, columns } = getDatagridContent(
          data,
          ["key"],
          editCallback
        );
        setRows(rows);
        setColumns(columns);
      });
    },
    [navigate]
  );

  useEffect(() => {
    fetchData(category);
  }, [fetchData, category]);

  const handleChange = async (event) => {
    setCategory(event.target.value);
  };

  const handleAdd = async (event) => {
    navigate("/contents/0");
  };

  if (loading) return <Loading />;

  return (
    <div id="content-list">
      <Button variant="contained" onClick={handleAdd}>
        +EKLE (İçerik)
      </Button>
      <br />
      <br />
      <Select
        labelId="category"
        id="category"
        value={category}
        label="Kategori"
        onChange={handleChange}
      >
        <MenuItem value={CONTENT_TYPE.News}>News (Haber)</MenuItem>
        <MenuItem value={CONTENT_TYPE.Announcement}>
          Announcement (Duyuru)
        </MenuItem>
        <MenuItem value={CONTENT_TYPE.Congress}>Congress (Kongre)</MenuItem>
        {/* <MenuItem value={CONTENT_TYPE.Document}>Document</MenuItem> */}
        <MenuItem value={CONTENT_TYPE.Education}>Education (Eğitim)</MenuItem>
      </Select>
      <br />
      <br />
      Toplam Kayıt: {rows.length}
      <br />
      <br />
      {rows.length ? (
        <GridView rows={rows} columns={columns} />
      ) : (
        "Kayıt bulunamadı"
      )}
    </div>
  );
};

export default ContentList;
