import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GridView from "components/GridView";
import Button from "@mui/material/Button";

import { getDatagridContent } from "utils/dataHelper";
import { fetchPolls } from "repos/poll";
import Loading from "views/_shared/Loading";

const PollList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    fetchPolls().then((data) => {
      setLoading(false);
      const editCallback = (params) => {
        navigate("/polls/" + params.id, { state: params.row });
      };

      const { rows, columns } = getDatagridContent(
        data,
        ["id", "key"],
        editCallback
      );
      setRows(rows);
      setColumns(columns);
    });
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = async (event) => {
    navigate("/polls/0");
  };

  if (loading) return <Loading />;

  return (
    <div id="poll-list">
      <Button variant="contained" onClick={handleAdd}>
        +EKLE (Anket)
      </Button>
      <br />
      <br />
      Toplam Kayıt: {rows.length}
      <br />
      <br />
      {rows.length ? (
        <GridView rows={rows} columns={columns} />
      ) : (
        "Kayıt bulunamadı"
      )}
    </div>
  );
};

export default PollList;
