import "./shared.css";

const Loading = () => {
  return (
    <div id="loading">
      <h1>Yükleniyor...</h1>
    </div>
  );
};

export default Loading;
