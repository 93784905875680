import { useCallback, useEffect, useState } from "react";
import GridView from "components/GridView";

import { getDatagridContent } from "utils/dataHelper";
import { fetchTargetLists } from "repos/target";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { deleteTargetList } from "repos/target";
import Loading from "views/_shared/Loading";

const TargetList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    fetchTargetLists().then((data) => {
      setLoading(false);
      const editCallback = (params) => {
        navigate("/targets/" + params.id, { state: params.row });
      };
      const { rows, columns } = getDatagridContent(data, ["key"], editCallback);
      setRows(rows);
      setColumns(columns);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = async (event) => {
    navigate("/targets/0");
  };

  if (loading) return <Loading />;

  return (
    <div id="target-list">
      <Button variant="contained" onClick={handleAdd}>
        +EKLE (Liste)
      </Button>
      <br />
      <br />
      Toplam Kayıt: {rows.length}
      <br />
      <br />
      {rows.length ? (
        <GridView rows={rows} columns={columns} />
      ) : (
        "Kayıt bulunamadı"
      )}
    </div>
  );
};

export default TargetList;
