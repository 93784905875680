import { Link } from "react-router-dom";
import { logout } from "utils/appHelper";

const NavBar = () => {
  const href = window.location.href.toLowerCase();

  return (
    <div className="navbar">
      <nav>
        <h4>EKRANLAR</h4>
        <Link
          to={`members`}
          className={href.includes("/members") ? "active" : "pristine"}
        >
          Üyeler
        </Link>
        <Link
          to={`contents`}
          className={href.includes("/contents") ? "active" : "pristine"}
        >
          İçerikler
        </Link>
        <Link
          to={`documents`}
          className={href.includes("/documents") ? "active" : "pristine"}
        >
          Dökümanlar
        </Link>
        <Link
          to={`groups`}
          className={href.includes("/groups") ? "active" : "pristine"}
        >
          Gruplar
        </Link>
        <Link
          to={`polls`}
          className={href.includes("/polls") ? "active" : "pristine"}
        >
          Anketler
        </Link>
        <Link
          to={`targets`}
          className={href.includes("/targets") ? "active" : "pristine"}
        >
          Hedef Listeler
        </Link>
        <Link
          to={`whitecodes`}
          className={href.includes("/whitecodes") ? "active" : "pristine"}
        >
          Beyaz kodlar
        </Link>
        <Link
          to={`campaigns`}
          className={href.includes("/campaigns") ? "active" : "pristine"}
        >
          Kampanyalar
        </Link>
        <Link
          to={`feedbacks`}
          className={href.includes("/feedbacks") ? "active" : "pristine"}
        >
          İletişim'den gelenler
        </Link>
        <Link
          to={`comment-complaints`}
          className={
            href.includes("/comment-complaints") ? "active" : "pristine"
          }
        >
          Yorum şikayetleri
        </Link>
        <Link
          to={`message-complaints`}
          className={
            href.includes("/message-complaints") ? "active" : "pristine"
          }
        >
          Mesaj şikayetleri
        </Link>
        <Link
          to={`push`}
          className={href.includes("/push") ? "active" : "pristine"}
        >
          Toplu Bildirim Gönderimi
        </Link>
        <hr />
        <h4>LİSTELER</h4>
        <Link
          to={`lookup/jobs`}
          className={href.includes("/jobs") ? "active" : "pristine"}
        >
          Meslek Listesi
        </Link>
        <Link
          to={`lookup/branches`}
          className={href.includes("/branches") ? "active" : "pristine"}
        >
          Branş Listesi
        </Link>
        <Link
          to={`lookup/cities`}
          className={href.includes("/cities") ? "active" : "pristine"}
        >
          İl Listesi
        </Link>
        <Link
          to={`lookup/counties`}
          className={href.includes("/counties") ? "active" : "pristine"}
        >
          İlçe Listesi
        </Link>
        <Link
          to={`lookup/titles`}
          className={href.includes("/titles") ? "active" : "pristine"}
        >
          Ünvanlar
        </Link>
        <hr />
        <Link
          to={`upload`}
          className={href.includes("/upload") ? "active" : "pristine"}
        >
          UPLOAD
        </Link>

        <hr />

        <Link to={`/`} onClick={logout}>
          <b>ÇIKIŞ</b>
        </Link>
      </nav>
    </div>
  );
};

export default NavBar;
