import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { deleteWhitecode, pushWhitecode } from "repos/whitecode";
import { showMessage } from "utils/appHelper";

const WhitecodeDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState({});

  useEffect(() => {
    const item = location?.state || {};
    setForm(item);
  }, [location]);

  const handleBack = () => {
    history.back();
  };

  const handleApprove = async () => {
    if (confirm("Onaylamak istediginize emin misiniz?")) {
      if (await pushWhitecode(form.id)) {
        showMessage("Onaylandı");
        handleBack();
      }
    }
  };

  const handleDelete = async () => {
    if (confirm("Silmek istediginize emin misiniz?")) {
      if (await deleteWhitecode(form.id)) {
        showMessage("Silindi");
        handleBack();
      }
    }
  };

  return (
    <div id="whitecode-save">
      <br />
      &nbsp;
      <br />
      <Button variant="contained" onClick={handleBack}>
        Listeye Geri Dön
      </Button>
      <br />
      &nbsp;
      <br />
      {[
        ["createDate", "Tarih"],
        ["id", ""],
        ["memberId", ""],
        ["fullName", "Talep Sahibi"],
        ["isPushed", ""],
        ["date", "Olay tarihi"],
        ["time", "Olay saati"],
        ["place", "Olay yeri"],
        ["suspects", "Şüpheliler"],
        ["story", "Olay detayları"],
      ].map(
        (el, i) =>
          el[1] && (
            <legend key={i}>
              <b>{el[1]}:</b>{" "}
              {typeof (form[el[0]] !== "string")
                ? form[el[0]]?.toString()
                : form[el[0]]}
            </legend>
          )
      )}
      {!form.isPushed && !form.DeleteDate && (
        <div>
          <Button variant="contained" tabIndex={0} onClick={handleApprove}>
            ONAYLA
          </Button>
          <br />
          <br />
          <Button
            variant="contained"
            color="error"
            tabIndex={0}
            onClick={handleDelete}
          >
            SİL
          </Button>
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default WhitecodeDetail;
