import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as memberRepo from "repos/member";
import { openImageInNewTab, showMessage } from "utils/appHelper";
import "./member.css";

const MemberSave = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState({
    id: "",
    avatar: "",
    phone: "",
    firstName: "",
    lastName: "",
    employer: "",
    active: false,
    city: "",
    county: "",
    job: "",
    branch: "",
    title: "",
    email: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    bio: "",
    gender: "",
  });

  useEffect(() => {
    if (location?.state?.id && !form.id) {
      memberRepo.getOneMember(location?.state?.id).then((info) => {
        setForm(info);
      });
    }
  }, [location]);

  const handleTextChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async (e) => {
    if (!form.firstName || !form.lastName || !form.employer) {
      alert("zorunlu alanları doldurunuz");
      return;
    }

    const item = {
      id: form.id,
      phone: form.phone,
      firstName: form.firstName,
      lastName: form.lastName,
      employer: form.employer,
      active: form.active,
    };

    const saved = await memberRepo.updateMember(item);
    if (saved) {
      showMessage("Kaydedildi");
      navigate("/members");
    }
  };

  const handleSwitch = (e) => {
    setForm({ ...form, active: e.target.checked });
  };

  return (
    <div id="member-save">
      <br />

      {form.avatar && (
        <a
          onClick={() =>
            openImageInNewTab(`data:image/jpeg;base64,${form.avatar}`)
          }
        >
          <img
            src={`data:image/jpeg;base64,${form.avatar}`}
            width={100}
            height={100}
          />
        </a>
      )}
      <br />
      <legend>
        <div>
          <b>ID:</b> {form.id}
        </div>
        <div>
          <b>Phone:</b> +90 {form.phone}
        </div>
      </legend>

      <legend>
        <b>FirstName: (Ad)</b>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={form.firstName}
          maxLength={30}
          onChange={handleTextChange}
        />
        <label className="counter">Kalan: {30 - form.firstName?.length}</label>
      </legend>

      <legend>
        <b>LastName: (Soyad)</b>
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={form.lastName}
          maxLength={30}
          onChange={handleTextChange}
        />
        <label className="counter">Kalan: {30 - form.lastName?.length}</label>
      </legend>

      <legend>
        <b> Employer: (Çalıştığı Kurum)</b>
        <input
          type="text"
          id="employer"
          name="employer"
          value={form.employer}
          maxLength={100}
          onChange={handleTextChange}
        />
        <label className="counter">Kalan: {100 - form.employer?.length}</label>
      </legend>

      <legend>
        <b>Aktif:</b> <Switch checked={form.active} onChange={handleSwitch} />
      </legend>

      <legend>
        <h4>Diğer profil bilgileri:</h4>
        <div>
          <b>city (il)</b>:{form.city}
        </div>
        <div>
          <b>county (ilce)</b>:{form.county}
        </div>
        <div>
          <b>job (meslek)</b>:{form.job}
        </div>
        <div>
          <b>branch (branş)</b>:{form.branch}
        </div>
        <div>
          <b>title (ünvan)</b>:{form.title}
        </div>
        <div>
          <b>email</b>:{form.email}
        </div>
        <div>
          <b>facebook</b>:{form.facebook}
        </div>
        <div>
          <b>twitter</b>:{form.twitter}
        </div>
        <div>
          <b>linkedin</b>:{form.linkedin}
        </div>
        <div>
          <b>instagram</b>:{form.instagram}
        </div>
        <div>
          <b>bio (biyografi)</b>:{form.bio}
        </div>
        <div>
          <b>gender (cinsiyet)</b>:{form.gender}
        </div>
      </legend>

      <div>
        <Button variant="contained" onClick={handleSave} className="large">
          KAYDET
        </Button>
      </div>
    </div>
  );
};

export default MemberSave;
